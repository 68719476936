<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('project_page.new_project_closing_requests')"
      :sub-title="$t('project_page.send_receipt')"
      btn
      variant="main"
      :btn-title="$t('common.orders_list')"
      classes="text-bold-15 text-white w-cus-sm-100"
      @btnAction="orderList"
    />
    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col lg="12" md="12" class="px-0">
          <wameed-no-data
            v-if="getAllProjects && getAllProjects.length < 1"
            icon="nodata-icon"
            :title="$t('offers_page.no_data')"
            :sub-title="$t('offers_page.no_data_text')"
          />
        </b-col>
        <b-col
          v-for="(item, index) in getAllProjects"
          :key="index"
          lg="3"
          md="3"
          class="py-3"
        >
          <orders-card
            :id="item.id"
            :company-name="item.company_name"
            :price="item.price"
            :title="item.hr_job_title"
            :date="item.created_at"
            :status-project="item.status"
          />
        </b-col>
      </div>

      <b-col
        v-if="getAllProjects && getAllProjects.length > 0"
        lg="12"
        md="12"
        class="px-0"
      >
        <wameed-pagination
          v-model="filterData.page"
          :page="filterData.page"
          :total-items="getTotalProjects.totalItems"
          :per_page="filterData.per_page"
          @changePage="changePage"
          @changeCurrentPage="changeCurrentPage"
        />
      </b-col>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import OrdersCard from '@/components/pages/orders/OrdersAgreedCard.vue';
// import WTables from '@/components/pages/applicants/WTables.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    OrdersCard,
  },
  data() {
    return {
      filterData: {
        status: '10',
        industry_preference: '',
        page: 1,
        per_page: 10,
        order_by: 'desc',
        search: '',
      },
      status: [
        {
          0: 'project_rejected',
          1: 'project_active',
          2: 'project_before_paying',
          3: 'project_under_review',
          4: 'project_offer_selection',
          5: 'project_agreed',
          6: 'project_canceled',
          7: 'project_pending',
          8: 'project_finished_employee_not_paid',
          9: 'project_finished',
          10: 'project_close_request',
          11: 'project_sent_by_employee',
          12: 'project_request_cancel',
        },
        {
          0: 'danger',
          1: 'warning',
          2: 'info',
          3: 'icon',
          4: 'success',
          5: 'warning',
          6: 'danger',
          7: 'icon',
          8: 'warning',
          9: 'success',
          10: 'icon',
          11: 'success',
          12: 'warning',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllProjects: 'getAllProjects',
      getTotalProjects: 'getTotalProjects',
    }),
  },
  watch: {},
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadProjects: 'loadProjects',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadProjects(this.filterData);
    },
    orderList() {
      this.$router.push({
        name: 'project-closing-requests-list',
        params: { lang: this.$i18n.locale },
      });
    },
  },
};
</script>
