<template>
  <b-card no-body class="cursor-pointer wameed-card h-100 m-0">
    <b-card-header class="flex-nowrap align-items-start pb-0">
      <b-media no-body class="flex-wrap w-100">
        <b-media-body>
          <div class="d-flex align-items-start justify-content-between w-100">
            <div>
              <h5 class="text-bold-18 text-font-main mb-1 mx-2">
                {{ companyName }}
              </h5>
              <h5 class="text-book-14 text-font-sub mb-3 mx-2">
                {{ formatedDate(date) }}
              </h5>
            </div>
          </div>

          <hr />
          <b-row
            class="package-details-table__body"
            style="background-color: inherit"
          >
            <b-col sm="6" class="package-details-name px-0">
              <p class="mb-0">{{ $t('project_page.price') }}:</p>
            </b-col>
            <b-col sm="6" class="package-details-value px-0">
              <p class="mb-0">{{ price }} {{ $t('common.sr_currency') }}</p>
            </b-col>
            <b-col sm="6" class="package-details-name px-0">
              <p class="mb-0">{{ $t('form_wizard_order.management_title_title') }}:</p>
            </b-col>
            <b-col sm="6" class="package-details-value px-0">
              <p class="mb-0">
                {{ title }}
              </p>
            </b-col>
            <b-col sm="12" class="package-details-value px-0">
              <wameed-btn
                classes="rounded-10 text-bold-15"
                block
                :title="$t('common.show_details')"
                type="button"
                :variant="'disable'"
                @submitAction="viewDetails(id)"
              />
            </b-col>
          </b-row>
        </b-media-body>
      </b-media>
    </b-card-header>
  </b-card>
</template>

<script>
import WameedBtn from '@/components/WameedBtn.vue';

export default {
  components: { WameedBtn },
  props: {
    id: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    companyName: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    price: {
      type: String,
      default: null,
    },
    statusProject: {
      type: Number,
      default: null,
    },
  },
  methods: {
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
    cardAction(id) {
      this.$emit('cardAction', id);
    },
    viewDetails(id) {
      this.$router.push({
        name: 'project-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
  },
};
</script>
